#dd-container {
  max-width: 854px;
  width: 90%;
  margin: 0 auto 0.25rem;

  .dtd-plugin {
    font-family: Merriweather, serif;
    font-size: 17px;
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    line-height: 1.5556;
  }

  details[open] > div > div,
  .dtd-plugin details[open] > div > div {
    color: $color-dark-grey;
  }

  .dtd-accordion-content,
  .dtd-plugin .dtd-accordion-content {
    margin: 0 5%;
  }

  .dtd-green,
  .dtd-plugin .dtd-green {
    background: $color-green;
    color: inherit;
    padding: 0.3em 0.4rem;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 0.9em;
  }

  .lets-check,
  .dtd-plugin .lets-check {
    text-transform: uppercase;
    border-radius: 0;
    font-size: 15px;
    box-sizing: border-box;
  }

  .dtd-search-box {
    margin-bottom: 1rem !important;
  }

  .mg-forms-button,
  .dtd-plugin .mg-forms-button {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    border-radius: 0;
    background-color: $color-accessibility-red !important;
    color: $color-white;
    border: none;
    font-size: 15px;
    position: relative;
    display: inline-block;
    padding: 20px;

    &:hover {
      background-color: $color-hover-red !important;
      color: $color-white !important;
    }

    &:after {
      content: '' !important;
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-bottom: 24px solid $color-hover-red;
      border-left: 24px solid transparent;
    }
  }

  *[class*='-header'],
  *[role='heading'],
  .amount-header {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }

  .dtd-footer,
  .dtd-plugin .dtd-footer {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    padding-top: 2rem;
    color: $color-white;

    a {
      color: $color-white !important;
      background: none !important;
      text-decoration: underline !important;
      font-weight: 400;
    }
  }

  a,
  .dtd-plugin a {
    color: inherit;
    transition: background-color 0.2s ease-in-out;
    border-bottom: 2px solid $color-yellow;
    word-wrap: break-word;
    text-decoration: none !important;

    &:not(.dtd-powered-by):hover {
      background-color: $color-yellow;
      color: inherit;
      color: $color-dark-grey;
    }
  }

  .search-another-top a,
  .dtd-plugin .search-another-top a {
    background-color: $color-yellow;
    font-size: 13px;
    text-transform: uppercase;
    border-radius: 0;
    border: none;
    color: $color-dark-grey;
    padding-top: 10px;

    &:hover {
      background-color: $color-accessibility-yellow;
    }
  }

  details,
  .dtd-plugin details {
    border-radius: 0;
    color: $color-dark-grey;
  }

  .summary-text,
  .dtd-plugin .summary-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }

  .Select-menu-outer,
  .dtd-plugin .Select-menu-outer {
    &,
    p {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .dtd-noresults,
  .dtd-plugin .dtd-noresults {
    padding: 1rem 3rem;
  }

  summary,
  .dtd-plugin summary {
    outline: none !important;
  }

  .dtd-callout,
  .dtd-plugin .dtd-callout {
    background: $color-dark-grey;
    color: $color-white;
    border: none;
    box-shadow: none;
    clip-path: polygon(100% 0, 100% calc(100% - 2.5rem), calc(100% - 2.5rem) 100%, 0 100%, 0 0);
  }
}
